const menuItems = [{
        id: 1,
        label: "Menu",
        isTitle: true
    },
    {
        id: 2,
        label: 'Dashboard',
        icon: 'far fa-chart-bar',
        badge: {
            variant: "primary",
            text: "2"
        },
        link: '/'
    },
    {
        id: 3,
        label: 'Conteúdo',
        icon: 'fas fa-pencil-ruler',
        subItems: [{
            id: 4,
            label: 'Cursos',
            link: '/courses'
        },
        {
            id: 5,
            label: 'Categorias',
            link: '/categories'
        },
        {
            id: 6,
            label: 'Habilidades',
            link: '/pedagogical/skills'
        },
        {
            id: 7,
            label: 'Autores',
            link: '/authors'
        },
        {
            id: 8,
            label: 'Aulas ao Vivo',
            link: '/live-classes'
        },
        {
            id: 9,
            label: 'Planos e Combos',
            link: '/plans'
        },
        ]
    },
    // {
    //     id: 10,
    //     label: 'Afiliados e Cupons',
    //     icon: 'fas fa-ticket-alt',
    //     subItems: [{
    //         id: 11,
    //         label: 'Afiliados',
    //         link: '/affiliateds'
    //     },
    //     {
    //         id: 12,
    //         label: 'Cupons',
    //         link: '/coupons'
    //     }
    //     ]
    // },    
    {
        id: 13,
        label: 'Alunos',
        icon: 'fas fa-graduation-cap',
        subItems: [{
            id: 14,
            label: 'Lista de Alunos',
            link: '/students'
        },
        {
            id: 15,
            label: 'Matricular Alunos',
            link: '/enrol_student'
        },    
        {
            id: 16,
            label: 'Grupos',
            link: '/groups'
        },
        ]
    },
    // {
    //     id: 17,
    //     label: 'Gamificação',
    //     icon: 'fas fa-gamepad',
    //     subItems: [{
    //         id: 18,
    //         label: 'Ranking',
    //         link: '/gamefication/ranking'
    //     },
    //     {
    //         id: 19,
    //         label: 'Escudos',
    //         link: '/gamefication/shields'
    //     },
    //     {
    //         id: 20,
    //         label: 'Pontuação',
    //         link: '/gamefication/triggers-points'
    //     }
    //     ]
    // },
    {
        id: 21,
        label: 'Tutoria',
        icon: 'fas fa-lightbulb',
        subItems: [{
            id: 22,
            label: 'Avaliações',
            link: '/pedagogical/reviews'
        },
        {
            id: 23,
            label: 'Duvidas dos Alunos',
            link: '/pedagogical/student-doubts'
        },
        {
            id: 24,
            label: 'Quadro de Avisos',
            link: '/messaging/warnings'
        },
        {
            id: 25,
            label: 'Comunidade',
            link: '/messaging/communities'
        },        
        {
            id: 26,
            label: 'Email',
            link: '/messaging/emails'
        }
        ]
    },
    {
        id: 27,
        label: 'Relatórios',
        icon: 'fas fa-clipboard-list',
        badge: {
            variant: 'success',
            text: '6'
        },
        subItems: [            
        {
            id: 28,
            label: 'Relatório de Vendas',
            link: '/reports/sales'
        },
        {
            id: 29,
            label: 'Relatórios de Matrículas',
            link: '/reports/enrols'
        },
        {
            id: 30,
            label: 'Matrículas em Planos',
            link: '/reports/contracts'
        },
        {
            id: 31,
            label: 'Qualidade',
            link: '/reports/quality'
        },
        {
            id: 32,
            label: 'Certificado',
            link: '/reports/certificates'
        },
        {
            id: 33,
            label: 'Exportações',
            link: '/reports/exports'
        },
        ]
    },
    // {
    //     id: 34,
    //     label: 'Gerenciamento',
    //     isTitle: true
    // },
    {
        id: 35,
        label: 'Configurações',
        icon: 'fas fa-sliders-h',
        subItems: [
        {
            id: 36,
            label: 'Planos do Sistema',
            link: '/client-plans/home'
        },
        {
            id: 37,
            label: 'Sistema',
            link: '/settings/system'
        },
        {
            id: 38,
            label: 'Configurações SMTP',
            link: '/settings/smtp'
        },
        {
            id: 39,
            label: 'Site',
            link: '/settings/frontend'
        },
        {
            id: 40,
            label: 'AVA',
            link: '/settings/ava'
        },
        {
            id: 41,
            label: 'Gateways',
            link: '/settings/gateways-credentials'
        },
        {
            id: 42,
            label: 'Certificado',
            link: '/settings/certificates'
        },
        {
            id: 43,
            label: 'Perguntas Frequentes',
            link: '/settings/common-questions'
        },
        {
            id: 44,
            label: 'Páginas Extras',
            link: '/settings/pages'
        },
        {
            id: 47,
            label: 'Minha Conta',
            link: '/manage-profile'
        },
        {
            id: 48,
            label: 'painelistradores',
            link: '/manage-painels'
        }
        ]
    }
];

export {
    menuItems
};